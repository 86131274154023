import { Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ProductSwitcher from "../../components/ProductDetailsComponents/ProductSwitcher/ProductSwitcher";
import PeriodPicker from "../../components/ProductDetailsComponents/PeriodPicker/PeriodPicker";
import { ProductDetailProvider } from "../../context/ProductDetailsContext";
import "./ProductDetailsContainer.css";

const ProductDetailsContainer = () => {
  return (
    <ProductDetailProvider>
      <Grid container className="yardInformation-grid-container">
        <div className="yardInformation-product-details-container">
          <Grid xxl={12} xl={12} lg={12} md={12} sm={6} xs={12}>
            <ProductSwitcher />
          </Grid>
          <div className="yardInformation-product-details-divider-container">
            <Divider
              orientation="vertical"
              className="yardInformation-product-details-divider"
            />
          </div>
          <Grid xxl={12} xl={12} lg={12} md={12} sm={6} xs={12}>
            <PeriodPicker />
          </Grid>
        </div>
      </Grid>
    </ProductDetailProvider>
  );
};

export default ProductDetailsContainer;

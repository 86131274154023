import Typography from "@mui/material/Typography";
import React from "react";
import "./BigTitleYard.css";

const BigTitleYard = ({ yardName }) => {
  return (
    <section className="yardInformation-big-title-yard-container">
      <Typography className="yardInformation-bigtitleyard-big-title">
        {yardName}
      </Typography>
    </section>
  );
};

export default BigTitleYard;

import YardInformationContainer from "./../containers/YardInformation/YardInformationContainer";
import ProductDetailsContainer from "../containers/ProductDetails/ProductDetailsContainer";
import { Dialog, Backdrop, Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import { useYardProductInformation } from "../context/YardProductInformationContext";
import "./YardProductInformation.css";
export default function YardProductInformation() {
  const { modalState, handleCloseModal, itemSelected, width } =
    useYardProductInformation();

  return (
    <section>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={modalState}
        onClose={() => handleCloseModal()}
        PaperProps={{
          className: "yardProductInformation-paper-props",
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          className: "yardProductInformation-backdrop-props",
        }}
      >
        <Grid
          container
          className="yardProductInformation-page-container"
          columns={13}
        >
          <Grid md={5} sm={13} fullWidth>
            <ProductDetailsContainer widthState={width} />
          </Grid>
          {width > 900 ? (
            <Grid md={1} className="yardProductInformation-divider-container">
              <Divider orientation="vertical" flexItem />
            </Grid>
          ) : (
            <Grid md={13} sm={13} xs={13}>
              <Divider />
            </Grid>
          )}
          <Grid md={7} sm={13}>
            <div className="yardProductInformation-yard-description-container">
              <YardInformationContainer itemSelected={itemSelected} />
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </section>
  );
}

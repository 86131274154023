import React, { useEffect } from "react";
import BigTitleYard from "../../components/YardInformationComponents/BigTitleYard/BigTitleYard";
import ItemDescription from "../../components/YardInformationComponents/ItemDescription/ItemDescription";
import LocationList from "../../components/YardInformationComponents/LocationList/LocationList";
import AddressLabel from "../../components/YardInformationComponents/AdrressLabel/AddressLabel";
import CarouselYardImages from "../../components/YardInformationComponents/Carousel/Carousel";
import { getYardInformation } from "../../services/YardServices";
import ImageModal from "../../components/YardInformationComponents/ImageModal/ImageModal";
import "./YardInformationContainer.css";
import { ModalProvider } from "../../context/ModalContext";

const YardInformationContainer = ({ itemSelected }) => {
  return (
    <div className="yardInformation-yard-description-container">
      <BigTitleYard yardName={itemSelected?.ItemId.CodeName} />
      <ItemDescription itemDescription={itemSelected?.ItemDescription} />
      <LocationList locationList={itemSelected?.ItemInformation} />
      {itemSelected.itemType === "Spots" && (
        <AddressLabel addressObject={itemSelected?.ItemAddressYard} />
      )}
      <ModalProvider>
        {/* <CarouselYardImages items={yardData?.images} /> */}
        {/* <ImageModal /> */}
      </ModalProvider>
    </div>
  );
};

export default YardInformationContainer;

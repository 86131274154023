// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yardInformation-yard-description-text {
    font-size: 70%;
    font-family: 'Roboto', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/YardInformationComponents/ItemDescription/ItemDescription.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iCAAiC;AACrC","sourcesContent":[".yardInformation-yard-description-text {\n    font-size: 70%;\n    font-family: 'Roboto', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

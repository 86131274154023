// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .yardInformation-location-text-title-list {
    font-size: 80%;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    margin-bottom: 3%;
}

body .yardInformation-location-array-list {
    font-size: 70%;
    font-family: 'Roboto', sans-serif;
}

body .yardInformation-location-container {
    margin-top: 3%;
}`, "",{"version":3,"sources":["webpack://./src/components/YardInformationComponents/LocationList/LocationList.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iCAAiC;IACjC,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iCAAiC;AACrC;;AAEA;IACI,cAAc;AAClB","sourcesContent":["body .yardInformation-location-text-title-list {\n    font-size: 80%;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 900;\n    margin-bottom: 3%;\n}\n\nbody .yardInformation-location-array-list {\n    font-size: 70%;\n    font-family: 'Roboto', sans-serif;\n}\n\nbody .yardInformation-location-container {\n    margin-top: 3%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

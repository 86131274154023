export const ObjectToBuyModel = (item) => {
  return {
    ItemPeriodSelected: item.periodSelected ? item.periodSelected : "Daily",
    ItemType: item.type,
    ItemDisplayName:
      item.type === "Spots" ? item.YardDisplayName : item.ChassisDisplayName,
    ItemId: item.type === "Spots" ? item.YardId : item.TypeChassisId,
    ItemAddressYard: item.type === "Spots" ? item.AddressYard : null,
    ItemAvailables: item.type === "Spots" ? item.SpotsAvailable : 100,
    ItemDescription: item.Description,
    ItemInformation:
      item.type === "Spots" ? item.pYardInformation : item.pChassisInformation,

    Services: item.Services,
  };
};

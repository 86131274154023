// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .yardInformation-address-title {
    font-size: 80%;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
}

body .yardInformation-address-text {
    font-size: 70%;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

body .yardInformation-address-container {
    margin-top: 3%;
    margin-bottom: 4%;
}`, "",{"version":3,"sources":["webpack://./src/components/YardInformationComponents/AdrressLabel/AddressLabel.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iCAAiC;IACjC,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB","sourcesContent":["body .yardInformation-address-title {\n    font-size: 80%;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 900;\n}\n\nbody .yardInformation-address-text {\n    font-size: 70%;\n    font-family: 'Roboto', sans-serif;\n    cursor: pointer;\n}\n\nbody .yardInformation-address-container {\n    margin-top: 3%;\n    margin-bottom: 4%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

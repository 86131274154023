// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .yardInformation-product-details-container {
    padding: 3%;
    height: 100%;
    text-align: center;
}

body .yardInformation-product-details-divider-container {
    display: flex;
    justify-content: center;
}

body .yardInformation-product-details-divider {
    width: 80%;
    border-bottom: 2px solid #f0f0f0;
    border-radius: 50%;
}

body .yardInformation-grid-container {
    display: flow;
}

@media (min-width: 600px) and (max-width: 899px) {

    /* Add your styles for screens up to 900 pixels here */
    body .yardInformation-product-details-container {
        display: flex;
    }

    body .yardInformation-grid-container {
        display: flex;
    }

    body .yardInformation-product-details-divider {
        width: 80%;
        border-bottom: 2px solid #f0f0f0;
        border-radius: 50%;
    }
}`, "",{"version":3,"sources":["webpack://./src/containers/ProductDetails/ProductDetailsContainer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,gCAAgC;IAChC,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;;IAEI,sDAAsD;IACtD;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,UAAU;QACV,gCAAgC;QAChC,kBAAkB;IACtB;AACJ","sourcesContent":["body .yardInformation-product-details-container {\n    padding: 3%;\n    height: 100%;\n    text-align: center;\n}\n\nbody .yardInformation-product-details-divider-container {\n    display: flex;\n    justify-content: center;\n}\n\nbody .yardInformation-product-details-divider {\n    width: 80%;\n    border-bottom: 2px solid #f0f0f0;\n    border-radius: 50%;\n}\n\nbody .yardInformation-grid-container {\n    display: flow;\n}\n\n@media (min-width: 600px) and (max-width: 899px) {\n\n    /* Add your styles for screens up to 900 pixels here */\n    body .yardInformation-product-details-container {\n        display: flex;\n    }\n\n    body .yardInformation-grid-container {\n        display: flex;\n    }\n\n    body .yardInformation-product-details-divider {\n        width: 80%;\n        border-bottom: 2px solid #f0f0f0;\n        border-radius: 50%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yardInformation-page-container {
    padding: 3%;

}

.yardInformation-divider-container {
    display: flex;
    justify-content: center;
}

.yardInformation-yard-description-container {
    padding: 3%;
}

body .yardInformation-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/containers/YardInformation/YardInformationContainer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;AAEf;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".yardInformation-page-container {\n    padding: 3%;\n\n}\n\n.yardInformation-divider-container {\n    display: flex;\n    justify-content: center;\n}\n\n.yardInformation-yard-description-container {\n    padding: 3%;\n}\n\nbody .yardInformation-loading-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

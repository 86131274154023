// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .yardProductInformation-page-container {
    padding: 3%;

}

body .yardProductInformation-divider-container {
    display: flex;
    justify-content: center;
}

body .yardProductInformation-yard-description-container {
    padding: 3%;
    height: 98%;
}

body .yardProductInformation-backdrop-props {
    backdrop-filter: blur(15px);
    background-color: rgba(34, 34, 79, 0.3);
}

body .yardProductInformation-paper-props {
    border-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/YardProductInformation.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;AAEf;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,2BAA2B;IAC3B,uCAAuC;AAC3C;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["body .yardProductInformation-page-container {\n    padding: 3%;\n\n}\n\nbody .yardProductInformation-divider-container {\n    display: flex;\n    justify-content: center;\n}\n\nbody .yardProductInformation-yard-description-container {\n    padding: 3%;\n    height: 98%;\n}\n\nbody .yardProductInformation-backdrop-props {\n    backdrop-filter: blur(15px);\n    background-color: rgba(34, 34, 79, 0.3);\n}\n\nbody .yardProductInformation-paper-props {\n    border-radius: 16px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { Typography, Link } from "@mui/material";
import "./AddressLabel.css";

const AddressLabel = ({ addressObject }) => {
  function handleOpenGoogleMaps() {
    window.open(
      `https://www.google.com/maps?q=${addressObject?.latitude},${addressObject?.longitude}`,
      "_blank"
    );
  }
  return (
    <>
      <section className="yardInformation-address-container">
        <Typography className="yardInformation-address-title">
          Address
        </Typography>

        <Link
          className="yardInformation-address-text"
          onClick={() => handleOpenGoogleMaps()}
        >
          {addressObject?.Address} {addressObject?.City} {addressObject?.State}{" "}
        </Link>
      </section>
    </>
  );
};
export default AddressLabel;

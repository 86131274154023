// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yardInformation-page-modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90% !important;
    max-height: 90% !important;
    background-color: white !important;
    border-radius: 10px;
    padding: 1% !important;
}

.yardInformation-image-modal {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/components/YardInformationComponents/ImageModal/ImageModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,yBAAyB;IACzB,0BAA0B;IAC1B,kCAAkC;IAClC,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".yardInformation-page-modal-box {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    max-width: 90% !important;\n    max-height: 90% !important;\n    background-color: white !important;\n    border-radius: 10px;\n    padding: 1% !important;\n}\n\n.yardInformation-image-modal {\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import * as React from "react";

const ModalContext = React.createContext();

export const useModal = () => React.useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalInformation, setModalInformation] = React.useState({
    modalState: false,
    modalImage: "",
  });

  function handleRenderModal(image) {
    setModalInformation({
      modalState: true,
      modalImage: image,
    });
  }

  function handleCloseImageModal() {
    setModalInformation({
      modalState: false,
      modalImage: "",
    });
  }

  return (
    <ModalContext.Provider
      value={{
        modalInformation,
        handleRenderModal,
        handleCloseImageModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

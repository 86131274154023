import Typography from "@mui/material/Typography";
import React from "react";
import "./ItemDescription.css";
const ItemDescription = ({ itemDescription }) => {
  return (
    <Typography
      variant="subtitle"
      className="yardInformation-yard-description-text"
    >
      {itemDescription}
    </Typography>
  );
};

export default ItemDescription;

import YardProductInformation from "./Pages/YardProductInformation";
import { YardProductInformationProvider } from "./context/YardProductInformationContext";
import "./root.css";

export default function Root() {
  return (
    <section>
      <YardProductInformationProvider>
        <YardProductInformation />
      </YardProductInformationProvider>
    </section>
  );
}

import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import "./LocationList.css";

const LocationList = ({ locationList }) => {
  if (!locationList || locationList.length === 0) {
    return <div>No hay ubicaciones disponibles.</div>;
  }

  return (
    <>
      <section className="yardInformation-location-container">
        <Typography
          variant="h2"
          className="yardInformation-location-text-title-list"
        >
          Yard Features
        </Typography>
        <Grid container spacing={1}>
          <Grid lg={8} md={8} sm={12} xs={12} container>
            {locationList.map((location) => (
              <Grid xs={6} key={location}>
                <Typography
                  key={location}
                  className="yardInformation-location-array-list"
                >
                  - {location}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid lg={4} md={4} sm={0} xs={0}></Grid>
        </Grid>
      </section>
    </>
  );
};
export default LocationList;

import * as React from "react";
import { ObjectToBuyModel } from "../Models/ObjectToBuyModel";
const YardProductInformationContext = React.createContext();

export const useYardProductInformation = () =>
  React.useContext(YardProductInformationContext);

export const YardProductInformationProvider = ({ children }) => {
  const [modalState, setModalState] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [itemSelected, setItemSelected] = React.useState("");
  const [itemObject, setItemObject] = React.useState({});
  function handleOpenModal() {
    setModalState(true);
  }

  function handleCloseModal() {
    setModalState(false);
  }

  React.useEffect(() => {
    window.addEventListener("openSubscriptionDialog", (e) => {
      const yardInformation = JSON.parse(e.detail.item);
      const Object = ObjectToBuyModel(yardInformation);
      setItemObject(Object);
      setItemSelected(Object);
      handleOpenModal();
    });
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <YardProductInformationContext.Provider
      value={{
        modalState,
        width,
        itemSelected,
        handleOpenModal,
        handleCloseModal,
        itemObject,
      }}
    >
      {children}
    </YardProductInformationContext.Provider>
  );
};

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Container, TextField, Button } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useProductDetail } from "../../../context/ProductDetailsContext";
import { useYardProductInformation } from "../../../context/YardProductInformationContext";
import dayjs from "dayjs";

import "./PeriodPicker.css";

const PeriodPicker = () => {
  const { itemObject } = useYardProductInformation();
  const {
    spots,
    productSelected,
    handleChangeNumberOfSpots,
    rangeOfAgreementDate,
    handleChangeStartDate,
    hanndleButtonBuyNow,
  } = useProductDetail();

  return (
    <>
      <Container>
        <Grid
          className="YardProductDetails-period-picker-container"
          container
          spacing={2}
        >
          <Grid
            xxl={8}
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={12}
            className="YardProductDetails-grid-start-date"
          >
            <Typography className="YardProductDetails-selector-title">
              Select contract start date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                // minDate={dayjs(new Date())}
                defaultValue={dayjs(rangeOfAgreementDate.startDate)}
                orientation="portrait"
                showDaysOutsideCurrentMonth
                onChange={(date) => handleChangeStartDate(date)}
                slotProps={{}}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            xxl={4}
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={12}
            className="YardProductDetails-grid-start-date"
          >
            <Typography className="YardProductDetails-selector-title">
              {itemObject.ItemType === "Spots" ? "Spots" : "Chassis"} Quantity
            </Typography>
            <TextField
              id="outlined-number"
              type="number"
              value={spots === 0 ? 1 : spots}
              disabled={productSelected === "Daily"}
              error={spots < 0}
              onChange={(e) => handleChangeNumberOfSpots(e)}
              inputProps={{
                min: 1,
                style: {
                  textAlign: "center",
                },
              }}
            />
          </Grid>
          <Grid xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className="YardProductDetails-billing-period-text">
              {itemObject.ItemAvailables <= 0 && itemObject.ItemType === "Spots"
                ? "No spots left"
                : itemObject.ItemType === "Spots"
                ? "Spots left" + itemObject.ItemAvailables
                : "Chassis left" + itemObject.ItemAvailables}
            </Typography>
            {/* <Typography className="YardProductDetails-billing-period-text">
              Initial billing period from{" "}
              {dayjs(rangeOfAgreementDate.startDate).format("MMMM D")} to{" "}
              {dayjs(rangeOfAgreementDate.endDate).format("MMMM D")}
            </Typography> */}
          </Grid>

          <Grid xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              disabled={
                (spots < 1 && productSelected !== "Daily") ||
                itemObject.ItemAvailables <= 0
              }
              className="YardProductDetails-button-buy-now"
              onClick={hanndleButtonBuyNow}
            >
              Add Product to Cart
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PeriodPicker;

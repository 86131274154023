// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .yardInformation-bigtitleyard-big-title {
    letter-spacing: 0.37px;
    font-size: 200%;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
}

body .yardInformation-bigtitleyard-complete-name {
    letter-spacing: 12.6px;
    font-size: 80%;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

body .yardInformation-big-title-yard-container {
    margin-bottom: 3%;
}`, "",{"version":3,"sources":["webpack://./src/components/YardInformationComponents/BigTitleYard/BigTitleYard.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,iCAAiC;IACjC,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,iCAAiC;IACjC,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["body .yardInformation-bigtitleyard-big-title {\n    letter-spacing: 0.37px;\n    font-size: 200%;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 900;\n}\n\nbody .yardInformation-bigtitleyard-complete-name {\n    letter-spacing: 12.6px;\n    font-size: 80%;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 900;\n    text-transform: uppercase;\n}\n\nbody .yardInformation-big-title-yard-container {\n    margin-bottom: 3%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

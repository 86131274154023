// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .yardInformation-carrousel-image {
    width: 155px;
    height: 81px;
    border-radius: 16px;
    scale: 0.7;
    margin: -2%;
    transition: all 0.5s ease-in-out;
}

body .yardInformation-carrousel-image:hover {
    cursor: pointer;
    scale: 0.85;
    transition: 0.5s;
}


body .yardInformation-carrousel-container {
    display: flex;
    justify-content: center;
    padding-right: 10%;
    padding-left: 10%;
}

body .yardInformation-carrousel-navigation-buttons {
    opacity: 1;
    background-color: white;
    color: #1c1c43;


}

body .yardInformation-carrousel-indicators {
    display: none;
}

@media (max-width: 600px) {
    body .yardInformation-carrousel-image {
        width: 50%;
        border-radius: 16px;
        scale: 0.8;
        margin: 1%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/YardInformationComponents/Carousel/Carousel.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,uBAAuB;IACvB,cAAc;;;AAGlB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,UAAU;QACV,mBAAmB;QACnB,UAAU;QACV,UAAU;IACd;AACJ","sourcesContent":["body .yardInformation-carrousel-image {\n    width: 155px;\n    height: 81px;\n    border-radius: 16px;\n    scale: 0.7;\n    margin: -2%;\n    transition: all 0.5s ease-in-out;\n}\n\nbody .yardInformation-carrousel-image:hover {\n    cursor: pointer;\n    scale: 0.85;\n    transition: 0.5s;\n}\n\n\nbody .yardInformation-carrousel-container {\n    display: flex;\n    justify-content: center;\n    padding-right: 10%;\n    padding-left: 10%;\n}\n\nbody .yardInformation-carrousel-navigation-buttons {\n    opacity: 1;\n    background-color: white;\n    color: #1c1c43;\n\n\n}\n\nbody .yardInformation-carrousel-indicators {\n    display: none;\n}\n\n@media (max-width: 600px) {\n    body .yardInformation-carrousel-image {\n        width: 50%;\n        border-radius: 16px;\n        scale: 0.8;\n        margin: 1%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
